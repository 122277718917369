import * as actions from '../actions/shipment-devices.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { DeviceConfiguration, DeviceMetadata } from '@cargo-signal/shared';

export interface State {
  devices: DeviceMetadata[];
  devicesLoading: boolean;
  devicesLoaded: boolean;
  deviceConfigurations: DeviceConfiguration[];
  deviceConfigurationsLoading: boolean;
  deviceConfigurationsLoaded: boolean;
  searching: boolean;
  searchResults: DeviceMetadata[];
  enableScanner: boolean;
  searchTerm: string;
}

export const initialState: State = {
  devices: null,
  devicesLoading: false,
  devicesLoaded: false,
  deviceConfigurations: [],
  deviceConfigurationsLoading: false,
  deviceConfigurationsLoaded: false,
  searching: false,
  searchResults: [],
  enableScanner: true,
  searchTerm: ''
};

const shipmentDevicesReducer = createReducer(
  initialState,
  on(actions.setShipmentDevices, (state, action) => ({
    ...state,
    devicesLoading: false,
    devicesLoaded: true,
    devices: [...action.devices]
  })),
  on(actions.loadDeviceConfigurations, state => ({
    ...state,
    deviceConfigurationsLoading: true,
    deviceConfigurationsLoaded: false
  })),
  on(actions.loadDeviceConfigurationsSuccess, (state, action) => ({
    ...state,
    deviceConfigurations: [...action.deviceConfigurations],
    deviceConfigurationsLoading: false,
    deviceConfigurationsLoaded: true
  })),
  on(actions.loadDeviceConfigurationsFail, state => ({
    ...state,
    deviceConfigurationsLoading: false,
    deviceConfigurationsLoaded: false
  })),
  on(actions.scanDeviceNumber, (state, action) => ({
    ...state,
    searching: true,
    searchResults: [],
    enableScanner: true,
    searchTerm: action.deviceNumber
  })),
  on(actions.scanDeviceNumberSuccess, state => ({
    ...state,
    enableScanner: false
  })),
  on(actions.scanDeviceNumberFail, state => ({
    ...state,
    searching: false
  })),
  on(actions.searchForDevice, (state, action) => ({
    ...state,
    searchTerm: action.searchStr,
    searching: true,
    searchResults: []
  })),
  on(actions.searchForDeviceSuccess, (state, action) => ({
    ...state,
    searching: false,
    searchResults: action.searchResults,
    enableScanner: true
  })),
  on(actions.clearDeviceSearch, state => ({
    ...state,
    searchTerm: '',
    searchResults: []
  })),
  on(actions.searchForDeviceFail, state => ({
    ...state,
    searching: false
  })),
  on(actions.assignDevice, state => ({
    ...state,
    deviceConfigurationsLoading: true,
    deviceConfigurationsLoaded: false
  })),
  on(actions.updateDeviceConfigurationSuccess, (state, action) => ({
    ...state,
    deviceConfigurations: action.devices,
    deviceConfigurationsLoading: false,
    deviceConfigurationsLoaded: true
  })),
  on(actions.updateDeviceConfigurationFail, (state, action) => ({
    ...state,
    deviceConfigurationsLoading: false,
    deviceConfigurationsLoaded: false
  })),
  on(actions.removeDeviceConfiguration, (state, action) => ({
    ...state,
    deviceConfigurationsLoading: true,
    deviceConfigurationsLoaded: false
  }))
);
export const getDeviceConfigurations = (state: State) => state.deviceConfigurations;
export const getDeviceConfigurationsLoading = (state: State) => state.deviceConfigurationsLoading;
export const getDeviceConfigurationsLoaded = (state: State) => state.deviceConfigurationsLoaded;
export const getDevices = (state: State) => state.devices;
export const getDevicesLoading = (state: State) => state.devicesLoading;
export const getSearching = (state: State) => state.searching;
export const getEnableScanner = (state: State) => state.enableScanner;
export const getSearchResults = (state: State) => state.searchResults;
export const getSearchTerm = (state: State) => state.searchTerm;

export function reducer(state: State | undefined, action: Action) {
  return shipmentDevicesReducer(state, action);
}
