import { createAction, props } from '@ngrx/store';

export const login = createAction('[Auth] Login', props<{ isGuestLogin: boolean }>());
export const handleCallback = createAction('[Auth] Handle Callback', props<{ url: string }>());
export const loginSuccess = createAction('[Auth] Login Success');
export const loginGuest = createAction('[Auth] Login Guest', props<{ path: string; token: string }>());
export const loginGuestSuccess = createAction('[Auth] Login Guest Success');
export const loginGuestFail = createAction('[Auth] Login Guest Fail');
export const logout = createAction('[Auth] Logout');
export const unauthorized = createAction('[Auth] Unauthorized', props<{ route: string }>());
