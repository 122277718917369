// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.panel {
  text-align: center;
}

.panel__contact {
  padding-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/core/auth/components/unauthorized/unauthorized.page.scss"],"names":[],"mappings":"AAAA;EAEE,YAAA;EACA,aAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AAAF;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,iBAAA;AAAF","sourcesContent":[".container {\n  // flexbox styles\n  height: 100%;\n  display: flex;\n  flex-flow: column;\n  justify-content: center;\n  align-items: center;\n  padding: 40px;\n}\n\n.panel {\n  text-align: center;\n}\n\n.panel__contact {\n  padding-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
