import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { filter, map, withLatestFrom } from 'rxjs/operators';

import * as fromFeature from '../../store';

@Injectable({
  providedIn: 'root'
})
export class ActualRouteLoadedGuard {
  constructor(private store$: Store<fromFeature.State>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkStore(next.params.shipmentId);
  }

  checkStore(shipmentId: string): Observable<boolean> {
    return this.store$.pipe(
      select(fromFeature.actualRouteLoaded),
      withLatestFrom(this.store$.pipe(select(fromFeature.actualRoute))),
      map(([loaded, actualRoute]) => {
        if (!loaded || (actualRoute && actualRoute.id !== shipmentId)) {
          this.store$.dispatch(fromFeature.loadActualRoute({ shipmentId }));
        }
        return loaded;
      }),
      filter(loaded => loaded)
    );
  }
}
