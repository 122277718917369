import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { delay, filter, map, tap } from 'rxjs/operators';
import { User, UserRole } from '@cargo-signal/shared';
import * as messagingActions from '../actions/messaging.actions';
import { CoreUtilsService } from '@core/services/core-utils/core-utils.service';

/**
 * The MessagingEffects class handles all ngrx side effects related to in-app messaging
 */
@Injectable()
export class MessagingEffects {
  constructor(private actions$: Actions, private store$: Store<any>, private utils: CoreUtilsService) {}

  checkMessaging$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(messagingActions.checkMessaging),
      filter(action => this.utils.isFeatureEnabled('messaging')),
      map(action => action.user),
      map((user: User) => {
        if (user && !user.roles.includes(UserRole.COMMAND_CENTER)) {
          return messagingActions.setUserInfo({ user });
        } else {
          return messagingActions.hideMessaging();
        }
      })
    );
  });

  setUserInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(messagingActions.setUserInfo),
      map(action => action.user),
      map((user: User) => {
        const hsq = ((window as any)._hsq = (window as any)._hsq || []);
        hsq.push([
          'identify',
          {
            email: user.emailAddress,
            firstName: user.firstName,
            lastName: user.lastName
          }
        ]);
        hsq.push(['trackPageView']);
        delay(2000); // allow login redirect to complete before displaying
        return messagingActions.showMessaging();
      })
    );
  });

  showMessaging$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(messagingActions.showMessaging),
        filter(() => this.utils.isFeatureEnabled('messaging')),
        tap(() => {
          const status = (window as any).HubSpotConversations.widget.status();
          if (status.loaded) {
            (window as any).HubSpotConversations.widget.refresh();
          } else {
            (window as any).HubSpotConversations.widget.load();
          }
        })
      ),
    { dispatch: false }
  );

  hideMessaging$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(messagingActions.hideMessaging),
        tap(() => {
          const status = (window as any).HubSpotConversations.widget.status();
          if (status.loaded) {
            (window as any).HubSpotConversations.widget.remove();
          }
        })
      ),
    { dispatch: false }
  );
}
