import { createSelector } from '@ngrx/store';
import * as fromRoot from '@app-root/store/reducers';
import * as fromShipmentAlert from '@app-root/store/reducers/shipment-alert.reducer';
import { AlertNotification, AlertStatus } from '@cargo-signal/shared';

export function getShipmentAlertState(state: fromRoot.State) {
  return state.shipmentAlert;
}

export const shipmentAlerts = createSelector(getShipmentAlertState, fromShipmentAlert.getShipmentAlerts);
export const openAlertCount = createSelector(shipmentAlerts, (alerts: AlertNotification[]) => {
  return alerts.filter(alert => alert.status !== AlertStatus.RESOLVED).length;
});
export const resolvedAlertCount = createSelector(shipmentAlerts, (alerts: AlertNotification[]) => {
  return alerts.filter(alert => alert.status === AlertStatus.RESOLVED).length;
});
export const shipmentAlertsLoading = createSelector(getShipmentAlertState, fromShipmentAlert.getShipmentAlertsLoading);
export const shipmentAlertsLoaded = createSelector(getShipmentAlertState, fromShipmentAlert.getShipmentAlertsLoaded);
