// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-logo {
  width: 154px;
  height: 48px;
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.menu-username {
  font-weight: bold;
  padding-bottom: 20px;
}

.menu-content {
  padding-left: 16px;
  padding-top: 16px;
}

.menu-header {
  position: relative;
}

.menu-account-select-title {
  display: none;
}

.menu-account-select {
  max-width: 100%;
}

.menu-close {
  position: absolute;
  right: 6px;
  top: 6px;
}`, "",{"version":3,"sources":["webpack://./src/app/core/menu/menu.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,oBAAA;AACF;;AAEA;EACE,iBAAA;EACA,oBAAA;AACF;;AAEA;EACE,kBAAA;EACA,iBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,UAAA;EACA,QAAA;AACF","sourcesContent":[".menu-logo {\n  width: 154px;\n  height: 48px;\n  padding-left: 16px;\n  padding-top: 16px;\n  padding-bottom: 16px;\n}\n\n.menu-username {\n  font-weight: bold;\n  padding-bottom: 20px;\n}\n\n.menu-content {\n  padding-left: 16px;\n  padding-top: 16px;\n}\n\n.menu-header {\n  position: relative;\n}\n\n.menu-account-select-title {\n  display: none; // Hidden in menu, displayed in modal\n}\n\n.menu-account-select {\n  max-width: 100%;\n}\n\n.menu-close {\n  position: absolute;\n  right: 6px;\n  top: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
