// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: var(--ion-color-light-tint);
  padding: 0px 40px 0px 40px;
}

.container h1 {
  font-weight: 500;
  color: var(--ion-color-danger);
}

.contact {
  padding-top: 20px;
  line-height: 25px;
}

.contact__cta {
  font-weight: 600;
  padding-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/core/auth/components/eula/eula.page.scss"],"names":[],"mappings":"AAAA;EAEE,YAAA;EACA,aAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,6CAAA;EACA,0BAAA;AAAF;;AAGA;EACE,gBAAA;EACA,8BAAA;AAAF;;AAGA;EACE,iBAAA;EACA,iBAAA;AAAF;;AAGA;EACE,gBAAA;EACA,mBAAA;AAAF","sourcesContent":[".container {\n  // flexbox styles\n  height: 100%;\n  display: flex;\n  flex-flow: column;\n  justify-content: center;\n  align-items: center;\n  background-color: var(--ion-color-light-tint);\n  padding: 0px 40px 0px 40px;\n}\n\n.container h1 {\n  font-weight: 500;\n  color: var(--ion-color-danger);\n}\n\n.contact {\n  padding-top: 20px;\n  line-height: 25px;\n}\n\n.contact__cta {\n  font-weight: 600;\n  padding-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
