import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { catchError, filter, map, take } from 'rxjs/operators';
import { ShipmentStatus } from '@cargo-signal/shared';

@Injectable({
  providedIn: 'root'
})
export class ShipmentsListLoadedGuard {
  constructor(private store$: Store<fromRoot.State>) {}

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkStore().pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    return this.store$.pipe(
      select(fromRoot.shipmentsLoaded),
      map(loaded => {
        if (!loaded) {
          this.store$.dispatch(fromRoot.loadShipmentsList());
        }
        return loaded;
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
