import { createAction, props } from '@ngrx/store';
import { ShipmentDetails } from '@cargo-signal/shared';
import { WizardStatus } from '@cargo-signal/shared/models/wizardStatus.model';

export const loadShipmentDetails = createAction(
  '[ShipmentDetails] Load Shipment Details',
  props<{ shipmentId: string, loadSensorHistory?: boolean }>()
);

export const loadShipmentDetailsSuccess = createAction(
  '[ShipmentDetails] Load Shipment Details Success',
  props<{ shipment: ShipmentDetails }>()
);

export const loadShipmentDetailsFail = createAction(
  '[ShipmentDetails] Load Shipment Details Fail',
  props<{ error: any }>()
);

export const setShipmentStatusComplete = createAction(
  '[ShipmentDetails] Set Shipment Status Complete',
  props<{ shipmentId: string }>()
);

export const setShipmentStatusCompleteSuccess = createAction('[ShipmentDetails] Set Shipment Status Complete Success');

export const setShipmentStatusCompleteFail = createAction(
  '[ShipmentDetails] Set Shipment Status Complete Fail',
  props<{ error: any }>()
);
export const validateShipment = createAction('[ShipmentDetails] Validate Shipment', props<{ shipmentId: string }>());

export const validateShipmentSuccess = createAction(
  '[ShipmentDetails] Validate Shipment Success',
  props<{ id: string; status: WizardStatus }>()
);

export const validateShipmentFail = createAction('[ShipmentDetails] Validate Shipment Fail', props<{ error: any }>());

export const resetShipmentDetails = createAction('[ShipmentDetails] Reset Shipment Details');
