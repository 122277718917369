import { createAction, props } from '@ngrx/store';
import { ShipmentCurrentSensorData } from '@cargo-signal/shared/models/shipment-current-sensor-data.model';
import { SensorHistoryEntry } from '@cargo-signal/shared';

export const loadCurrentSensorData = createAction(
  '[Sensor Data] Load Current Sensor Data',
  props<{ shipmentId: string }>()
);
export const loadCurrentSensorDataSuccess = createAction(
  '[Sensor Data] Load Current Sensor Data Success',
  props<{ sensorData: ShipmentCurrentSensorData }>()
);
export const loadCurrentSensorDataFail = createAction(
  '[Sensor Data] Load Current Sensor Data Fail',
  props<{ error: any }>()
);
export const loadSensorHistory = createAction(
  '[Sensor Data] Load Sensor History Data',
  props<{ shipmentId: string, startDate: string, endDate: string }>()
);
export const loadSensorHistorySuccess = createAction(
  '[Sensor Data] Load Sensor History Data Success',
  props<{ sensorHistory: SensorHistoryEntry[] }>()
);
export const loadSensorHistoryFail = createAction(
  '[Sensor Data] Load Sensor History Data Fail',
  props<{ error: any }>()
);
