import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import * as routerActions from '../actions/router.actions';

/**
 * This class is used to handle side effects for different router actions
 */
@Injectable()
export class RouterEffects {
  constructor(private actions$: Actions, private router: Router, private location: Location) {}

  /**
   * Intercepts Go actions and updates the browsers location to the path specified.
   */
  navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActions.go),
        tap(({ path, query: queryParams, extras, displayRoute }) => {
          // TODO: 'skipLocationChange' not working as expected in v8/9 of Angular - EmmA Murray 11/23/2021
          // https://github.com/angular/angular/issues/16981
          // replaceState is a workaround to display the correct URL after navigation when using 'skipLocationChange'
          if (extras && extras.skipLocationChange && displayRoute) {
            // the use of `replaceUrl`  here is to help bust the ionic cache when forcing navigation via actions
            this.router.navigate(path, { replaceUrl: true, queryParams, ...(extras as object) }).then(() => {
              this.location.replaceState(displayRoute);
            });
          } else {
            // the use of `replaceUrl`  here is to help bust the ionic cache when forcing navigation via actions
            this.router.navigate(path, { replaceUrl: true, queryParams, ...(extras as object) });
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Intercepts Back actions and updates the browser's location to the previous location
   */
  navigateBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActions.back),
        tap(() => this.location.back())
      ),
    { dispatch: false }
  );

  /**
   * Intercepts Forward actions and updates browsers location to the next location
   */
  navigateForward$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActions.forward),
        tap(() => this.location.forward())
      ),
    { dispatch: false }
  );
}
