import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '@env';
import { ActivatedRouteSnapshot, Params, RouterStateSnapshot } from '@angular/router';
import * as fromShipmentAlert from './shipment-alert.reducer';
import * as fromShipmentDevices from './shipment-devices.reducer';
import * as fromShipmentDetails from './shipment-details.reducer';
import * as fromShipmentsList from './shipments-list.reducer';
import * as fromShipmentRoutes from './shipment-routes.reducer';
import * as fromRouter from '@ngrx/router-store';
import * as fromUser from './user.reducer';
import * as fromAuth from './auth.reducer';
import * as fromSensorData from './sensor-data.reducer';
import * as fromMapObjects from './map-objects.reducer';

/**
 * The router url state
 */
export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

export interface State {
  shipments: fromShipmentsList.State;
  shipmentRoutes: fromShipmentRoutes.State;
  routerReducer: fromRouter.RouterReducerState<RouterStateUrl>;
  userReducer: fromUser.UserState;
  shipmentAlert: fromShipmentAlert.State;
  shipmentDevices: fromShipmentDevices.State;
  shipmentDetails: fromShipmentDetails.State;
  authReducer: fromAuth.AuthState;
  sensorDataReducer: fromSensorData.State;
  mapObjectsReducer: fromMapObjects.State;
}

export const reducers: ActionReducerMap<State> = {
  shipments: fromShipmentsList.reducer,
  routerReducer: fromRouter.routerReducer,
  userReducer: fromUser.userReducer,
  shipmentAlert: fromShipmentAlert.reducer,
  shipmentDevices: fromShipmentDevices.reducer,
  shipmentDetails: fromShipmentDetails.reducer,
  authReducer: fromAuth.authReducer,
  shipmentRoutes: fromShipmentRoutes.reducer,
  sensorDataReducer: fromSensorData.sensorDataReducer,
  mapObjectsReducer: fromMapObjects.reducer
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];

export const getShipmentsListState = createFeatureSelector<fromShipmentsList.State>('shipments');

export const getShipmentRoutesState = createFeatureSelector<fromShipmentRoutes.State>('shipmentRoutes');

/**
 * Helps provide a snapshot of the router state without impacting application performance.
 * See [here]{@link https://github.com/ngrx/platform/blob/master/docs/router-store/api.md#custom-router-state-serializer}
 * for more info.
 */
export class CustomSerializer implements fromRouter.RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const { queryParams } = routerState.root;
    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }
    const { params } = state;
    return { url, queryParams, params };
  }
}
