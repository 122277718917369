/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { Route, RouteFeature } from '@cargo-signal/shared';
import { Point } from 'geojson';

export const loadPlannedRoute = createAction('[Route] Load Planned Route', props<{ shipmentId: string }>());

export const loadPlannedRouteSuccess = createAction(
  '[Route] Load Planned Route Success',
  props<{ plannedRoute: Route }>()
);

export const loadPlannedRouteFail = createAction('[Route] Load Planned Route Fail', props<{ error: any }>());
export const loadActualRoute = createAction('[Route] Load Actual Route', props<{ shipmentId: string }>());

export const loadActualRouteSuccess = createAction(
  '[Route] Load Actual Route Success',
  props<{ actualRoute: Route }>()
);

export const loadActualRouteFail = createAction('[Route] Load Actual Route Fail', props<{ err: any }>());

export const drawActualRoute = createAction('[Route] Draw Actual Route', props<{ actualRoute: Route }>());

export const setCurrentLocation = createAction(
  '[ShipmentDetails] Set Current Location',
  props<{ currentLocation: RouteFeature<Point> }>()
);

export const resetRoutes = createAction('[Route] Reset Routes');
