import * as actions from '../actions/shipment-details.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { ShipmentDetails, ShipmentStatus } from '@cargo-signal/shared';

export interface State {
  shipment: ShipmentDetails;
  loading: boolean;
  loaded: boolean;
}

export const initialState: State = {
  shipment: null,
  loading: false,
  loaded: false
};

const shipmentDetailsReducer = createReducer(
  initialState,
  on(actions.loadShipmentDetails, (state, action) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(actions.loadShipmentDetailsSuccess, (state, action) => ({
    ...state,
    shipment: action.shipment,
    loading: false,
    loaded: true
  })),
  on(actions.loadShipmentDetailsFail, (state, action) => ({
    ...state,
    loading: false,
    loaded: false
  })),
  on(actions.setShipmentStatusComplete, (state, action) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(actions.setShipmentStatusCompleteSuccess, (state, action) => ({
    shipment: {
      ...state.shipment,
      status: ShipmentStatus.COMPLETE
    },
    loading: false,
    loaded: true
  })),
  on(actions.setShipmentStatusCompleteFail, (state, action) => ({
    ...state,
    loading: false,
    loaded: false
  })),
  on(actions.resetShipmentDetails, (state, action) => initialState)
);
export const getShipment = (state: State) => state.shipment;
export const getShipmentLoading = (state: State) => state.loading;
export const getShipmentLoaded = (state: State) => state.loaded;

export function reducer(state: State | undefined, action: Action) {
  return shipmentDetailsReducer(state, action);
}
