import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eula',
  templateUrl: './eula.page.html',
  styleUrls: ['./eula.page.scss']
})
export class EulaPageComponent {
  constructor() {}
}
