import { createAction, props } from '@ngrx/store';
import { CompanyPermissionGroupMapping, User } from '@cargo-signal/shared';

export const getUser = createAction('[User] Get User');

export const getUserSuccess = createAction('[User] Get User Success', props<{ user: User }>());

export const setActiveCompany = createAction(
  '[User] Set Active Company',
  props<{ company: CompanyPermissionGroupMapping }>()
);

export const setActiveCompanySuccess = createAction(
  '[User] Set Active Company Success',
  props<{ company: CompanyPermissionGroupMapping }>()
);

export const setActiveCompanyFail = createAction('[User] Set Active Company Fail', props<{ error: string }>());

export const setGuestView = createAction('[User] Set Guest View', props<{ guestView: boolean }>());
