import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService, CompanyPermissionGroupMapping, User } from '@cargo-signal/shared';
import { select, Store } from '@ngrx/store';
import * as fromStore from '@app-root/store';
import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
  alive = true;
  user$: Observable<User>;
  name$: Observable<string>;
  guestView$: Observable<boolean>;
  activeCompany: CompanyPermissionGroupMapping;

  constructor(
    private authService: AuthService,
    private store$: Store<fromStore.State>,
    private menuController: MenuController
  ) {}

  ngOnInit() {
    this.user$ = this.store$.select(fromStore.selectCurrentUser);
    this.name$ = this.store$.select(fromStore.selectName);
    this.guestView$ = this.store$.select(fromStore.selectGuestView);
    this.store$
      .pipe(
        select(fromStore.selectActiveCompany),
        takeWhile(() => this.alive)
      )
      .subscribe(newCompany => {
        this.activeCompany = newCompany;
      });
  }

  onSelect($event) {
    this.store$.dispatch(fromStore.setActiveCompany({ company: $event }));
    this.menuController.close();
  }

  signOut() {
    this.store$.dispatch(fromStore.logout());
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
