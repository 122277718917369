import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import * as fromActions from '../actions/app.actions';
import { PollingManagerService } from '@core/services/polling-manager/polling-manager.service';

@Injectable()
export class AppEffects {
  constructor(private actions$: Actions, private pollingManager: PollingManagerService) {}

  pollForData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.poll),
      switchMap(() => {
        return this.pollingManager.getActionsForPolling();
      })
    )
  );
}
