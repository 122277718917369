import { Route, RouteFeature } from '@cargo-signal/shared';
import * as actions from '../actions/shipment-routes.actions';
import { Action, createReducer, on } from '@ngrx/store';

export interface State {
  plannedRoute: Route;
  actualRoute: Route;
  currentLocation: RouteFeature;
  plannedRouteLoading: boolean;
  plannedRouteLoaded: boolean;
  actualRouteLoading: boolean;
  actualRouteLoaded: boolean;
}

export const initialState: State = {
  plannedRoute: null,
  actualRoute: null,
  currentLocation: null,
  plannedRouteLoading: false,
  plannedRouteLoaded: false,
  actualRouteLoading: false,
  actualRouteLoaded: false
};

const shipmentRoutesReducer = createReducer(
  initialState,
  on(actions.loadPlannedRoute, (state, action) => ({
    ...state,
    plannedRouteLoading: true,
    plannedRouteLoaded: false
  })),
  on(actions.loadPlannedRouteSuccess, (state, action) => ({
    ...state,
    plannedRouteLoading: false,
    plannedRouteLoaded: true,
    plannedRoute: action.plannedRoute
  })),
  on(actions.loadPlannedRouteFail, (state, action) => ({
    ...state,
    plannedRouteLoading: false,
    plannedRouteLoaded: false
  })),
  on(actions.loadActualRoute, (state, action) => ({
    ...state,
    actualRouteLoading: true,
    actualRouteLoaded: false
  })),

  on(actions.loadActualRouteSuccess, (state, action) => ({
    ...state,
    actualRoute: action.actualRoute,
    actualRouteLoading: false,
    actualRouteLoaded: true
  })),
  on(actions.loadActualRouteFail, (state, action) => ({
    ...state,
    actualRouteLoading: false,
    actualRouteLoaded: false
  })),
  on(actions.setCurrentLocation, (state, action) => ({
    ...state,
    currentLocation: action.currentLocation
  })),
  on(actions.resetRoutes, () => initialState)
);

export const getPlannedRoute = (state: State) => state.plannedRoute;
export const getPlannedRouteLoading = (state: State) => state.plannedRouteLoading;
export const getPlannedRouteLoaded = (state: State) => state.plannedRouteLoaded;
export const getActualRoute = (state: State) => state.actualRoute;
export const getActualRouteLoading = (state: State) => state.actualRouteLoading;
export const getActualRouteLoaded = (state: State) => state.actualRouteLoaded;
export const getCurrentLocation = (state: State) => state.currentLocation;

export function reducer(state: State | undefined, action: Action) {
  return shipmentRoutesReducer(state, action);
}
