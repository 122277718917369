import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '@cargo-signal/shared';
import { from, of } from 'rxjs';
import * as authActions from '../actions/auth.actions';
import * as routerActions from '../actions/router.actions';
import * as userActions from '../actions/user.actions';
import * as messagingActions from '../actions/messaging.actions';

@Injectable()
export class AuthEffects {
  constructor(private actions$: Actions, private store$: Store<any>, private authService: AuthService) {}

  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.login),
        tap(action => this.authService.login(action.isGuestLogin))
      ),
    { dispatch: false }
  );

  handleCallback$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.handleCallback),
      exhaustMap(action => {
        return from(this.authService.getClient()).pipe(
          exhaustMap(client => client.handleRedirectCallback(action.url)),
          exhaustMap((callbackResolve: any) => {
            const guestView = callbackResolve.appState?.login === 'passwordless';
            return this.authService.refresh(false).pipe(
              exhaustMap(token => {
                this.authService.token = token;
                return [
                  userActions.setGuestView({ guestView }),
                  authActions.loginSuccess(),
                  routerActions.go({ path: [''] })
                ];
              })
            );
          })
        );
      })
    );
  });

  loginSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.loginSuccess, authActions.loginGuestSuccess),
      switchMap(() => {
        this.authService.fetchUserPreferences().subscribe(prefs => {
          if (prefs) {
            localStorage.setItem('cs.userPreferences', JSON.stringify(prefs));
          }
        });
        return [userActions.getUser()];
      })
    );
  });

  loginGuest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.loginGuest),
      switchMap(action => {
        this.authService.token = action.token;
        if (this.authService.authenticated) {
          this.authService.refresh(false);
          return [authActions.loginGuestSuccess(), routerActions.go({ path: action.path.split('/') })];
        } else {
          return [authActions.loginGuestFail(), authActions.unauthorized({ route: '/login' })];
        }
      })
    );
  });

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.logout),
      switchMap(() => this.authService.logout().pipe(catchError(err => of(console.log(err))))),
      map(() => {
        localStorage.clear();
        // clear HubSpot user information
        (window as any)._hsp.push(['revokeCookieConsent']);
        return messagingActions.hideMessaging();
      })
    )
  );

  unauthorized$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.unauthorized),
      map(unauthorized => {
        return routerActions.go({
          path: ['unauthorized'],
          extras: { skipLocationChange: true },
          displayRoute: unauthorized.route
        });
      })
    );
  });
}
