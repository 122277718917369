import { Injectable } from '@angular/core';
import { AuthConfig } from '@cargo-signal/shared';

import { environment } from '../../../../environments/environment';

export enum EnvironmentVariable {
  PRODUCTION = 'production',
  HMR = 'hmr',
  TRACE_ROUTING = 'traceRouting',
  GOOGLE_MAPS_API_KEY = 'googleMapsApiKey',
  OPEN_WEATHER_MAPS_API_KEY = 'openWeatherMapsApiKey',
  LOGGER_LEVEL = 'loggerLevel',
  ENABLE_POLLING = 'enablePolling',
  POLLING_DURATION = 'pollingDuration',
  ENV_NAME = 'envName',
  APP_URL_PREFIX = 'appUrlPrefix',
  AUTH = 'auth',
  FEATURE_FLAGS = 'featureFlags'
}

@Injectable({
  providedIn: 'root'
})
export class CoreUtilsService {
  constructor() {}

  getEnvironmentVariable(
    variable: EnvironmentVariable
  ): boolean | undefined | number | string | AuthConfig | { [key: string]: boolean } {
    return environment[variable];
  }

  /**
   * Used to check whether a certain feature is enabled in the front end.
   *
   * Note: long term this should be replaced with Gitlabs
   */
  isFeatureEnabled(featureName: string): boolean {
    const featureFlags: { [key: string]: boolean } = this.getEnvironmentVariable(EnvironmentVariable.FEATURE_FLAGS) as {
      [key: string]: boolean;
    };
    if (!featureFlags) {
      throw new Error('Feature Flags are not present in the project!');
    }
    if (featureFlags.hasOwnProperty(featureName)) {
      return featureFlags[featureName] as boolean;
    } else {
      console.warn(`Feature flag not present for ${featureName}`);
      return false;
    }
  }
}
