import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AlertService } from '@cargo-signal/shared';
import * as actions from '@app-root/store/actions';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class ShipmentAlertEffects {
  constructor(private actions$: Actions, private alertService: AlertService) {}

  loadShipmentAlerts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadShipmentAlerts),
      map(action => action.shipmentId),
      filter(shipmentId => shipmentId !== undefined),
      switchMap(shipmentId => {
        return this.alertService.fetchShipmentAlerts(shipmentId).pipe(
          map(shipmentAlerts => {
            return actions.loadShipmentAlertsSuccess({ shipmentAlerts });
          }),
          catchError(error => of(actions.loadShipmentAlertsFail({ error })))
        );
      })
    );
  });
}
