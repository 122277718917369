import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromSensorData from '../reducers/sensor-data.reducer';
import { SensorHistoryEntry, ShipmentCurrentSensorData } from '@cargo-signal/shared';
import { SensorData } from '@cargo-signal/shared/models/sensor-data.model';

export function getState(state: fromFeature.State) {
  return state.sensorDataReducer;
}

export const selectCurrentSensorData = createSelector(getState, fromSensorData.getCurrentSensorData);
export const selectCurrentSensorDataLoading = createSelector(getState, fromSensorData.getCurrentSensorDataLoading);
export const selectCurrentSensorDataLoaded = createSelector(getState, fromSensorData.getCurrentSensorDataLoaded);
export const selectSensorHistory = createSelector(getState, fromSensorData.getSensorHistory);
export const selectSensorHistoryLoading = createSelector(getState, fromSensorData.getSensorHistoryLoading);
export const selectSensorHistoryLoaded = createSelector(getState, fromSensorData.getSensorHistoryLoaded);

/**
 * All types with either current sensor data or history
 */
export const selectSensorData = createSelector(
  selectCurrentSensorData,
  selectSensorHistory,
  (current: ShipmentCurrentSensorData, histories: SensorHistoryEntry[]) => {
    const allTypes = ['temperature', 'humidity', 'light', 'batteryPercentage', 'pressure', 'shock', 'tilt'];
    const dataTypes = new Map<string, SensorData>();

    allTypes.map(type => {
      // Add type and CurrentSensorData if available
      if (current) {
        for (const data of current.currentData) {
          if (data.type === type) {
            dataTypes.set(type, data);
            break;
          }
        }
      }
      // If only history is available, add type with null value
      if (histories && !dataTypes.get(type)) {
        for (const history of histories) {
          if (history[type]) {
            dataTypes.set(type, null);
            break;
          }
        }
      }
    });
    return dataTypes;
  }
);
