import { Injectable } from '@angular/core';
import * as actions from '../actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Pageable, ShipmentDetails, ShipmentDetailsFeature, ShipmentService } from '@cargo-signal/shared';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import * as routerActions from '@app-root/store/actions/router.actions';
import * as fromReducers from '@app-root/store/reducers';
import * as fromSelectors from '@app-root/store/selectors';
import { select, Store } from '@ngrx/store';
import { Feature, Point } from 'geojson';
import {
  searchCompleteCriteria,
  searchCriteriaCurrentShipments,
  searchCriteriaDraftShipments
} from '@app-root/store/effects/shipmentSearch';

@Injectable()
export class ShipmentsListEffects {
  constructor(
    private actions$: Actions,
    private shipmentService: ShipmentService,
    private store$: Store<fromReducers.State>
  ) {}

  loadShipmentsList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadShipmentsList),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectGuestView))),
      switchMap(([_, guestView]: [any, boolean]) => {
        const searchCriteria = searchCriteriaCurrentShipments;
        return this.shipmentService.fetchShipmentFeatures(searchCriteria, guestView, 0, 20000).pipe(
          mergeMap(firstResponse => {
            const requests: Observable<Pageable<ShipmentDetailsFeature>>[] = [of(firstResponse)];
            for (let i = 1; i < firstResponse.totalPages; i++) {
              requests.push(this.shipmentService.fetchShipmentFeatures(searchCriteria, guestView, i, 20000));
            }
            return forkJoin(requests);
          }),
          map(pageableShipmentFeatures => {
            const shipments: Feature<Point, ShipmentDetails>[] = pageableShipmentFeatures.reduce(
              (acc, data) => acc.concat(data.content),
              []
            );
            return actions.loadShipmentsListSuccess({ shipments });
          }),
          catchError(error => {
            return of(actions.loadShipmentsListFail({ error }));
          })
        );
      })
    );
  });

  loadDraftShipmentsList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadDraftShipmentsList),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectGuestView))),
      switchMap(([_, guestView]: [any, boolean]) => {
        return this.shipmentService.fetchShipmentFeatures(searchCriteriaDraftShipments, guestView, 0, 20000).pipe(
          mergeMap(firstResponse => {
            const requests: Observable<Pageable<ShipmentDetailsFeature>>[] = [of(firstResponse)];
            for (let i = 1; i < firstResponse.totalPages; i++) {
              requests.push(
                this.shipmentService.fetchShipmentFeatures(searchCriteriaDraftShipments, guestView, i, 20000)
              );
            }
            return forkJoin(requests);
          }),
          map(pageableShipmentFeatures => {
            const shipments: Feature<Point, ShipmentDetails>[] = pageableShipmentFeatures.reduce(
              (acc, data) => acc.concat(data.content),
              []
            );
            return actions.loadDraftShipmentsListSuccess({ shipments });
          }),
          catchError(error => {
            return of(actions.loadDraftShipmentsListFail({ error }));
          })
        );
      })
    );
  });

  loadCompleteShipmentsList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadCompleteShipmentsList),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectGuestView))),
      switchMap(([_, guestView]: [any, boolean]) => {
        return this.shipmentService.fetchShipmentFeatures(searchCompleteCriteria, guestView, 0, 20000).pipe(
          mergeMap(firstResponse => {
            const requests: Observable<Pageable<ShipmentDetailsFeature>>[] = [of(firstResponse)];
            for (let i = 1; i < firstResponse.totalPages; i++) {
              requests.push(this.shipmentService.fetchShipmentFeatures(searchCompleteCriteria, guestView, i, 20000));
            }
            return forkJoin(requests);
          }),
          map(pageableShipmentFeatures => {
            const shipments: Feature<Point, ShipmentDetails>[] = pageableShipmentFeatures.reduce(
              (acc, data) => acc.concat(data.content),
              []
            );
            return actions.loadCompleteShipmentsListSuccess({ shipments });
          }),
          catchError(error => {
            return of(actions.loadCompleteShipmentsListFail({ error }));
          })
        );
      })
    );
  });

  loadShipmentsListFail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadShipmentsListFail),
      map(() => {
        return routerActions.go({ path: ['unauthorized'], extras: { skipLocationChange: true } });
      })
    );
  });
}
