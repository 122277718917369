// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  clientID: 'NB292Ou2H02afUCjfYRczh6R20KIZRPI',
  domain: 'qa-auth.cargosignal.dev',
  redirect_uri: 'https://qa-m.cargosignal.dev/callback',
  authScope: 'openid profile email',
  audience: 'https://qa-app.cargosignal.com',
  appUrlPrefix: 'https://qa-m.cargosignal.dev',
  whitelistedDomains: ['localhost:4200', 'cargosignal.dev', 'qa-m.cargosignal.dev'],
  mapboxApiKey: 'pk.eyJ1IjoiY2FyZ29zaWduYWwiLCJhIjoiY2tkdWZ2Z3FkMTFiaDJzbXB4d3ByZ3hueSJ9.gBEOnUcuyQ6GIWujELrNEw',
  mapboxChinaApiKey: 'pk.eyJ1IjoiY2FyZ29zaWduYWwiLCJhIjoiY2tkdWZ2Z3FkMTFiaDJzbXB4d3ByZ3hueSJ9.gBEOnUcuyQ6GIWujELrNEw',
  featureFlags: {
    messaging: true
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
