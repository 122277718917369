const plannedRouteLayerConfig = {
  id: 'shipment-planned-route',
  type: 'line',
  source: 'shipment-planned-route',
  layout: {
    'line-join': 'round',
    'line-cap': 'round'
  },
  paint: {
    'line-color': '#1A4283',
    'line-opacity': 0.4,
    'line-width': 4
  }
};

const actualRouteLocationsLayerConfig = {
  id: 'shipment-actual-route-points',
  type: 'circle',
  source: 'shipment-actual-route-points',
  paint: {
    'circle-color': '#4E92FF',
    'circle-opacity': 0.7,
    'circle-radius': 6,
    'circle-stroke-color': '#FFFFFF',
    'circle-stroke-width': 2
  }
};

const actualRouteLayerConfig = {
  id: 'shipment-actual-route',
  type: 'line',
  source: 'shipment-actual-route',
  layout: {
    'line-join': 'round',
    'line-cap': 'round'
  },
  paint: {
    'line-color': '#1A4283',
    'line-width': 4
  }
};

const genericWaypointLayerConfig = {
  id: 'shipment-planned-route-waypoints',
  type: 'fill',
  source: 'shipment-planned-route-waypoints',
  layout: {},
  paint: {
    'fill-color': '#D500F9',
    'fill-opacity': 0.3
  }
};

const genericWaypointLayerOutline = {
  id: 'shipment-planned-route-waypoints-outline',
  type: 'line',
  source: 'shipment-planned-route-waypoints',
  layout: {
    'line-join': 'round',
    'line-cap': 'round'
  },
  paint: {
    'line-color': '#D500F9',
    'line-opacity': 0.4,
    'line-width': 4
  }
};

export {
  actualRouteLayerConfig,
  genericWaypointLayerConfig,
  genericWaypointLayerOutline,
  plannedRouteLayerConfig,
  actualRouteLocationsLayerConfig
};
