import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { NavbarComponent } from '@core/navbar/navbar.component';
import { IonicModule } from '@ionic/angular';
import { MenuComponent } from '@core/menu/menu.component';
import { UserHasScopeDirective } from '@shared/directives/user-has-scope.directive';
import { FormattedDatePipe } from '@shared/pipes/formatted-date.pipe';

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, HttpClientModule, IonicModule],
  declarations: [NavbarComponent, MenuComponent, UserHasScopeDirective, FormattedDatePipe],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    NavbarComponent,
    MenuComponent,
    UserHasScopeDirective,
    FormattedDatePipe
  ]
})
export class SharedModule {}
