import { createSelector } from '@ngrx/store';
import * as fromRoot from '../reducers';
import * as fromShipmentDevices from '../reducers/shipment-devices.reducer';

export function getShipmentDevicesState(state: fromRoot.State) {
  return state.shipmentDevices;
}

export const shipmentDevices = createSelector(getShipmentDevicesState, fromShipmentDevices.getDevices);
export const scannerEnabled = createSelector(getShipmentDevicesState, fromShipmentDevices.getEnableScanner);
export const searchingDevices = createSelector(getShipmentDevicesState, fromShipmentDevices.getSearching);
export const shipmentDevicesLoading = createSelector(getShipmentDevicesState, fromShipmentDevices.getDevicesLoading);
export const searchResults = createSelector(getShipmentDevicesState, fromShipmentDevices.getSearchResults);
export const searchTerm = createSelector(getShipmentDevicesState, fromShipmentDevices.getSearchTerm);
export const deviceConfigurations = createSelector(
  getShipmentDevicesState,
  fromShipmentDevices.getDeviceConfigurations
);
