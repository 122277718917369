import { createSelector } from '@ngrx/store';

import * as fromRoot from '../reducers/index';
import * as fromAuth from '../reducers/auth.reducer';

export function getAuthState(state: fromRoot.State) {
  return state.authReducer;
}

export const selectIsLoggedIn = createSelector(getAuthState, fromAuth.getIsLoggedIn);
export const selectAuthLoading = createSelector(getAuthState, fromAuth.getLoading);
export const selectAuthLoaded = createSelector(getAuthState, fromAuth.getLoaded);
