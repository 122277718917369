import { ShipmentAlertEffects } from './shipment-alert.effects';
import { ShipmentsListEffects } from './shipments-list.effects';
import { UserEffects } from './user.effects';
import { ShipmentDevicesEffects } from './shipment-devices.effects';
import { ShipmentDetailsEffects } from './shipment-details.effects';
import { ShipmentRoutesEffects } from './shipment-routes.effects';
import { RouterEffects } from './router.effects';
import { AppEffects } from './app.effects';
import { AuthEffects } from './auth.effects';
import { SensorDataEffects } from './sensor-data.effects';
import { MapObjectsEffects } from './map-objects.effects';
import { MessagingEffects } from '@app-root/store/effects/messaging.effects';

export const effects: any[] = [
  ShipmentAlertEffects,
  ShipmentsListEffects,
  ShipmentDevicesEffects,
  ShipmentDetailsEffects,
  ShipmentRoutesEffects,
  RouterEffects,
  AppEffects,
  UserEffects,
  AuthEffects,
  SensorDataEffects,
  MapObjectsEffects,
  MessagingEffects
];

export * from './shipment-alert.effects';
export * from './shipment-devices.effects';
export * from './shipment-details.effects';
export * from './shipments-list.effects';
export * from './shipment-routes.effects';
export * from './user.effects';
export * from './router.effects';
export * from './app.effects';
export * from './auth.effects';
export * from './sensor-data.effects';
export * from './map-objects.effects';
export * from './messaging.effects';
