// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-card {
  margin: 0;
  color: #000000;
  border-radius: 13px;
  width: 343px;
}
.popup-card ion-card-title {
  font-size: 16px;
  font-weight: 500;
}
.popup-card ion-card-subtitle {
  color: #000000;
  font-size: 14px;
}
.popup-card ion-card-content {
  padding-top: 0;
}
.popup-card ion-item {
  padding-bottom: 16px;
}
.popup-card ion-item ion-label {
  margin: 0;
}
.popup-card ion-item ion-label ion-text h5 {
  font-size: 13px;
  border: none;
  padding-bottom: 4px;
  font-weight: 500;
}
.popup-card ion-item ion-label ion-text p {
  font-size: 13px;
  color: #373a3d;
}
.popup-card .popup-link {
  color: #005aeb;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}

.mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-popup-content {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/shipment/pages/map/map-popup/map-popup.component.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,cAAA;EACA,mBAAA;EACA,YAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;AACJ;AAEE;EACE,cAAA;EACA,eAAA;AAAJ;AAGE;EACE,cAAA;AADJ;AAIE;EACE,oBAAA;AAFJ;AAII;EACE,SAAA;AAFN;AAKQ;EACE,eAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;AAHV;AAMQ;EACE,eAAA;EACA,cAAA;AAJV;AAUE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;AARJ;;AAYA;EACE,aAAA;AATF;;AAYA;EACE,UAAA;AATF","sourcesContent":[".popup-card {\n  margin: 0;\n  color: #000000;\n  border-radius: 13px;\n  width: 343px;\n\n  ion-card-title {\n    font-size: 16px;\n    font-weight: 500;\n  }\n\n  ion-card-subtitle {\n    color: #000000;\n    font-size: 14px;\n  }\n\n  ion-card-content {\n    padding-top: 0;\n  }\n\n  ion-item {\n    padding-bottom: 16px;\n\n    ion-label {\n      margin: 0;\n\n      ion-text {\n        h5 {\n          font-size: 13px;\n          border: none;\n          padding-bottom: 4px;\n          font-weight: 500;\n        }\n\n        p {\n          font-size: 13px;\n          color: #373a3d;\n        }\n      }\n    }\n  }\n\n  .popup-link {\n    color: #005aeb;\n    font-size: 16px;\n    font-weight: 500;\n    text-decoration: none;\n  }\n}\n\n.mapboxgl-popup-tip {\n  display: none;\n}\n\n.mapboxgl-popup-content {\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
