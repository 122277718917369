import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '@app-root/store';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  @Input() title = '';
  @Input() path = '';
  @Input() showBackButton = false;

  constructor(private store$: Store<fromStore.State>) {}

  back(path) {
    if (!path) {
      this.store$.dispatch(fromStore.back());
    } else {
      this.store$.dispatch(fromStore.go({ path: [path] }));
    }
  }
}
