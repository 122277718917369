import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NotifierService {
  constructor(private toastController: ToastController, private alertController: AlertController) {}

  async showNotification(message: string) {
    const toast = await this.toastController.create({
      color: 'dark',
      duration: 1000,
      position: 'middle',
      message
    });
    toast.present();
  }

  async showAlertDialog(header: string, message: string) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: [
        {
          text: 'Close',
          role: 'cancel'
        }
      ]
    });

    await alert.present();
  }
}
