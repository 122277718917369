// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-avatar {
  max-width: 30px;
  max-height: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/core/navbar/navbar.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;AACF","sourcesContent":["ion-avatar {\n  max-width: 30px;\n  max-height: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
