import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import * as fromStore from '@app-root/store';
import { MapService } from '@cargo-signal/atlas-mapbox';

@Injectable({
  providedIn: 'root'
})
export class ResetShipmentDetailsGuard {
  constructor(private store$: Store<fromRoot.State>, private mapService: MapService) {}

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.store$.dispatch(fromStore.resetShipmentDetails());
    this.store$.dispatch(fromStore.resetRoutes());
    this.store$.dispatch(fromStore.resetShipmentAlerts());
    return true;
  }
}
