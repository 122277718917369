import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, mapToCanActivate, mapToCanActivateChild } from '@angular/router';
import { AuthGuard } from '@core/auth/guards/auth.guard';
import { ShipmentsListLoadedGuard } from '@app-root/shipment/guards';
import { UserGuard } from '@core/auth/guards/user.guard';
import { UserScopeGuard } from '@core/auth/guards/user-scope.guard';
import { PermissionScope } from '@cargo-signal/shared';
import { LoginPageComponent } from '@core/auth/components/login/login.page';
import { DraftShipmentsListLoadedGuard } from '@app-root/shipment/guards/draft-shipments-list-loaded.guard';
import { CompleteShipmentsListLoadedGuard } from '@app-root/shipment/guards/complete-shipments-list-loaded.guard';
import { EulaPageComponent } from './core/auth/components/eula/eula.page';
import { UnauthorizedPageComponent } from './core/auth/components/unauthorized/unauthorized.page';

const routes: Routes = [
  {
    path: '',
    canActivate: mapToCanActivate([AuthGuard, UserGuard]),
    canActivateChild: mapToCanActivateChild([AuthGuard, UserGuard]),
    children: [
      {
        path: '',
        redirectTo: 'shipments',
        pathMatch: 'full'
      },
      {
        path: 'shipments',
        canActivate: mapToCanActivate([UserScopeGuard]),
        canActivateChild: mapToCanActivateChild([
          UserScopeGuard,
          ShipmentsListLoadedGuard,
          DraftShipmentsListLoadedGuard,
          CompleteShipmentsListLoadedGuard
        ]),
        data: {
          requiredScopes: [PermissionScope.READ_SHIPMENTS]
        },
        loadChildren: () =>
          import('./shipment/pages/shipments-list/shipments-list.module').then(m => m.ShipmentListPageModule)
      }
    ]
  },
  {
    path: 'callback',
    redirectTo: 'shipments'
  },
  {
    path: 'login',
    component: LoginPageComponent
  },
  {
    path: 'eula',
    canActivate: mapToCanActivate([AuthGuard]),
    component: EulaPageComponent
  },
  {
    path: 'unauthorized',
    component: UnauthorizedPageComponent
  },
  {
    path: '**',
    // need to preserve the original path, not redirect
    component: UnauthorizedPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
