import { Component, OnDestroy, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { timer } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from './store';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  alive = true;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private store$: Store<fromRoot.State>
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    this.startPolling();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  /**
   * Refresh shipments data every 10 minutes
   */
  startPolling() {
    timer(0, 600000)
      .pipe(
        takeWhile(() => this.alive),
        tap(() => {
          this.store$.dispatch(fromRoot.poll());
        })
      )
      .subscribe();
  }
}
