import { NavigationExtras } from '@angular/router';
import { createAction, props } from '@ngrx/store';

/**
 * The Go Action is used to redirect the browser to a new path in the application
 */
export const go = createAction(
  '[Router] Go',
  props<{
    path: any[];
    query?: object;
    extras?: NavigationExtras;
    displayRoute?: string;
  }>()
);

/**
 * The Back Action is used to redirect the browser to the previous path
 */
export const back = createAction('[Router] Back');

/**
 * The Forward Action is used to redirect the browser to the previous path
 */
export const forward = createAction('[Router] Forward');
