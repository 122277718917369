import { AlertNotification } from '@cargo-signal/shared';
import { Action, createReducer, on } from '@ngrx/store';
import * as actions from '../actions/shipment-alert.actions';

export interface State {
  shipmentAlerts: AlertNotification[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: State = {
  shipmentAlerts: [],
  loading: false,
  loaded: false
};

const shipmentAlertReducer = createReducer(
  initialState,
  on(actions.resetShipmentAlerts, state => initialState),
  on(actions.loadShipmentAlerts, state => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(actions.loadShipmentAlertsSuccess, (state, action) => ({
    ...state,
    shipmentAlerts: action.shipmentAlerts,
    loading: false,
    loaded: true
  })),
  on(actions.loadShipmentAlertsFail, (state, action) => ({
    ...state,
    loaded: false,
    loading: false
  }))
);

export const getShipmentAlerts = (state: State) => state.shipmentAlerts;
export const getShipmentAlertsLoading = (state: State) => state.loading;
export const getShipmentAlertsLoaded = (state: State) => state.loaded;

export function reducer(state: State | undefined, action: Action) {
  return shipmentAlertReducer(state, action);
}
