import { ShipmentDetails, ShipmentService, ShipmentStatus, WizardStatus } from '@cargo-signal/shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { catchError, filter, map, mergeMap, retryWhen, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as actions from '../actions';
import { of, throwError, timer } from 'rxjs';
import { NotifierService } from '@core/services/notifier/notifier.service';
import { select, Store } from '@ngrx/store';
import { shipmentDetails } from '@app-root/store/selectors';
import * as fromReducers from '../reducers';

@Injectable()
export class ShipmentDetailsEffects {
  constructor(
    private store$: Store<fromReducers.State>,
    private actions$: Actions,
    private shipmentService: ShipmentService,
    private notifierService: NotifierService
  ) {}

  completeShipment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.setShipmentStatusComplete),
      map(action => action.shipmentId),
      switchMap(shipmentId => {
        return this.shipmentService.setShipmentStatusToComplete(shipmentId).pipe(
          switchMap(shipment => {
            // @ts-ignore
            if (shipment.shipmentStatus === ShipmentStatus.COMPLETE) {
              return [
                actions.setShipmentStatusCompleteSuccess(),
                actions.loadShipmentsList(),
                actions.loadDraftShipmentsList(),
                actions.loadCompleteShipmentsList()
              ];
            } else {
              return of(actions.setShipmentStatusCompleteFail({ error: 'Status update failed' }));
            }
          }),
          catchError(error => of(actions.setShipmentStatusCompleteFail({ error })))
        );
      })
    );
  });

  shipmentStatusCompleteSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.setShipmentStatusCompleteSuccess),
      map(() => {
        this.notifierService.showNotification('Shipment successfully completed');
        return actions.go({ path: ['/shipments'] });
      })
    );
  });

  validateShipment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.validateShipment),
      map(action => action.shipmentId),
      switchMap(shipmentId => {
        return this.shipmentService.validateShipment(shipmentId).pipe(
          map((status: WizardStatus) => actions.validateShipmentSuccess({ id: shipmentId, status })),
          catchError(error => of(actions.validateShipmentFail({ error })))
        );
      })
    );
  });

  shipmentValid$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.validateShipmentSuccess),
      filter(action => action.status.validOverall),
      tap(_ => {
        this.notifierService.showNotification('Shipment validated successfully');
      }),
      map(payload => {
        return actions.go({
          path: ['/shipments']
        });
      })
    );
  });

  shipmentInvalid$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.validateShipmentSuccess),
        map(action => action.status),
        filter(status => !status.validOverall),
        tap(status => {
          this.checkStepValidation('Step 1', status.detail.STEP1);
          this.checkStepValidation('Step 2', status.detail.STEP2);
          this.checkStepValidation('Step 3', status.detail.STEP3);
          this.checkStepValidation('Step 4', status.detail.STEP4);
        })
      );
    },
    { dispatch: false }
  );

  validateShipmentFail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.validateShipmentFail),
      map(action => action.error),
      tap(() =>
        this.notifierService.showAlertDialog(
          'Sorry, we were unable to validate this shipment.',
          'This can be due to missing or incomplete information. Please contact an Admin or the Command Center to resolve.'
        )
      )
    );
  });

  loadShipmentDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadShipmentDetails),
      switchMap(action => {
        return this.shipmentService.fetchShipmentDetails(action.shipmentId).pipe(
          withLatestFrom(this.store$.pipe(select(shipmentDetails))),
          switchMap(([shipment, latestShipment]: [ShipmentDetails, ShipmentDetails]) => {
            if (
              latestShipment?.shipmentId === shipment.shipmentId &&
              latestShipment?.devices.length === shipment.devices.length
            ) {
              throw 201;
            }
            const actionsToReturn: any[] = [
              actions.loadShipmentDetailsSuccess({ shipment }),
              actions.setShipmentDevices({ devices: shipment.devices })
            ];
            if (!!action.loadSensorHistory) {
              actionsToReturn.push(
                actions.loadSensorHistory({
                  shipmentId: action.shipmentId,
                  startDate: shipment.shipmentInitiatedDateTime,
                  endDate: shipment.shipmentCompletedDateTime
                })
              );
            }
            return actionsToReturn;
          }),
          retryWhen(errors => {
            return errors.pipe(
              mergeMap((error, i) => {
                const retryAttempt = i + 1;
                if (retryAttempt > 6) {
                  return throwError(error);
                } else {
                  return timer(1000);
                }
              })
            );
          }),
          catchError(error => {
            return of(actions.loadShipmentDetailsFail({ error }));
          })
        );
      })
    );
  });

  private checkStepValidation(stepName: string, step: { valid: boolean; messages: string[] }) {
    if (!step.valid) {
      const messages = step.messages.join('; ');
      this.notifierService.showAlertDialog(
        'Sorry, we were unable to validate this shipment.',
        'This can be due to missing or incomplete information. Please contact an Admin or the Command Center to resolve.'
      );
    }
  }
}
