import { Feature, FeatureCollection } from 'geojson';
import { clusterConfig, clusterCountConfig, pointConfig } from '../../../assets/shipment-list-map.config';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '@app-root/store/actions';
import { DynamicComponentService, MapService } from '@cargo-signal/atlas-mapbox';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import * as mapboxGl from 'mapbox-gl';
import { MapPopupComponent } from '@app-root/shipment/pages/map/map-popup/map-popup.component';
import { select, Store } from '@ngrx/store';
import * as fromSelectors from '@app-root/store/selectors';

@Injectable()
export class MapObjectsEffects {
  constructor(
    private actions$: Actions,
    private store$: Store,
    private mapService: MapService,
    private dynamicComponentService: DynamicComponentService
  ) {}

  loadMapLayers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadMapLayers),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getShipmentsByStatus))),
      switchMap(([, shipments]: [any, Feature[]]) => {
        const source = 'shipments';
        const featureLayer = { type: 'FeatureCollection', features: shipments } as FeatureCollection;
        const sourceOptions = { cluster: true };

        // add data layer to the map
        return of(
          this.mapService.addGeoJsonDataLayer(
            featureLayer,
            [pointConfig],
            source,
            sourceOptions,
            false,
            this.openPopup,
            {
              closeButton: false
            }
          )
        ).pipe(
          map(() => {
            const atlasMap = this.mapService.getActiveMap();
            atlasMap.resize();
            // cluster layers must be added separately so clicking them doesn't create a popup
            this.mapService.addLayer(source, { ...clusterConfig, source });
            this.mapService.addLayer(source, { ...clusterCountConfig, source });
            // zoom in on clusters on click
            atlasMap.on('click', 'clusters', this.clusterZoom);
            return actions.loadMapLayersSuccess();
          }),
          catchError(error => {
            return of(actions.loadMapLayersFail({ error }));
          })
        );
      })
    );
  });

  private clusterZoom = (e: mapboxGl.MapMouseEvent) => {
    const atlasMap = this.mapService.getActiveMap();
    const features = atlasMap.queryRenderedFeatures(e.point, {
      layers: ['clusters']
    });
    const clusterId = features[0].properties.cluster_id;
    atlasMap.getSource('shipments').getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) {
        return;
      }

      atlasMap.easeTo({
        center: features[0].geometry.coordinates,
        zoom
      });
    });
  }

  private openPopup = (e: mapboxGl.MapMouseEvent) => {
    return this.dynamicComponentService.injectComponent(MapPopupComponent, x => {
      x.shipment = e.features[0].properties;
    });
  }
}
