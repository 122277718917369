import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromUser from '../reducers/user.reducer';
import { User } from '@cargo-signal/shared';
import { getUser } from '../actions';

export function getUserState(state: fromFeature.State) {
  return state.userReducer;
}

export const selectUserLoading = createSelector(getUserState, fromUser.getLoading);
export const selectUserLoaded = createSelector(getUserState, fromUser.getLoaded);
export const selectCurrentUser = createSelector(getUserState, fromUser.getUser);
export const selectActiveCompany = createSelector(getUserState, fromUser.getActiveCompany);

export const selectScopes = createSelector(selectCurrentUser, (user: User) => {
  return user ? user.scopes : [];
});

/**
 * Use user's full name if available, otherwise use email address before @
 */
export const selectName = createSelector(selectCurrentUser, (user: User) => {
  return user.firstName && user.lastName ? user.firstName.concat(' ', user.lastName) : user.emailAddress.split('@')[0];
});

export const selectGuestView = createSelector(getUserState, fromUser.getGuestView);
