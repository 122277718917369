const clusterConfig = {
  id: 'clusters',
  type: 'circle',
  source: 'shipments',
  filter: ['has', 'point_count'],
  layout: {
    visibility: 'visible'
  },
  paint: {
    'circle-color': '#277AFF',
    'circle-radius': ['step', ['get', 'point_count'], 24, 10, 32, 20, 44]
  }
};

const clusterCountConfig = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'shipments',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-size': 20
  },
  paint: {
    'text-color': '#FFFFFF'
  }
};

const pointConfig = {
  id: 'unclustered-point',
  type: 'circle',
  source: 'shipments',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': '#005AEB',
    'circle-radius': 8,
    'circle-stroke-width': 2,
    'circle-stroke-color': '#fff'
  }
};

export { clusterConfig, clusterCountConfig, pointConfig };
