import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '@app-root/store';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})
export class LoginPageComponent {
  constructor(private store$: Store<fromStore.State>) {}

  loginUser() {
    this.store$.dispatch(fromStore.login({ isGuestLogin: false }));
  }

  loginGuest() {
    this.store$.dispatch(fromStore.login({ isGuestLogin: true }));
  }
}
