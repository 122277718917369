import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-detail-map',
  templateUrl: './detail-map-popup.component.html',
  styleUrls: ['./detail-map-popup.component.scss']
})
export class DetailMapPopupComponent {
  @Input() entry: { address: string; timestamp: string };
}
