import { createReducer, on } from '@ngrx/store';
import { SensorHistoryEntry, ShipmentCurrentSensorData } from '@cargo-signal/shared';
import * as fromSensorData from '../actions/sensor-data.actions';

export interface State {
  currentSensorData: ShipmentCurrentSensorData;
  currentSensorDataLoading: boolean;
  currentSensorDataLoaded: boolean;
  sensorHistory: SensorHistoryEntry[];
  sensorHistoryLoading: boolean;
  sensorHistoryLoaded: boolean;
}

export const initialState: State = {
  currentSensorData: null,
  currentSensorDataLoading: false,
  currentSensorDataLoaded: false,
  sensorHistory: [],
  sensorHistoryLoading: false,
  sensorHistoryLoaded: false
};

export const sensorDataReducer = createReducer(
  initialState,
  on(fromSensorData.loadCurrentSensorData, (state, action) => ({
    ...state,
    currentSensorDataLoading: true,
    currentSensorDataLoaded: false
  })),
  on(fromSensorData.loadCurrentSensorDataSuccess, (state, action) => ({
    ...state,
    currentSensorDataLoading: false,
    currentSensorDataLoaded: true,
    currentSensorData: action.sensorData
  })),
  on(fromSensorData.loadCurrentSensorDataFail, (state, action) => ({
    ...state,
    currentSensorDataLoading: false,
    currentSensorDataLoaded: false
  })),
  on(fromSensorData.loadSensorHistory, (state, action) => ({
    ...state,
    sensorHistoryLoading: true,
    sensorHistoryLoaded: false
  })),
  on(fromSensorData.loadSensorHistorySuccess, (state, action) => ({
    ...state,
    sensorHistoryLoading: false,
    sensorHistoryLoaded: true,
    sensorHistory: action.sensorHistory
  })),
  on(fromSensorData.loadCurrentSensorDataFail, (state, action) => ({
    ...state,
    sensorHistoryLoading: false,
    sensorHistoryLoaded: false
  }))
);

export const getCurrentSensorData = (state: State) => state.currentSensorData;
export const getCurrentSensorDataLoading = (state: State) => state.currentSensorDataLoading;
export const getCurrentSensorDataLoaded = (state: State) => state.currentSensorDataLoaded;
export const getSensorHistory = (state: State) => state.sensorHistory;
export const getSensorHistoryLoading = (state: State) => state.sensorHistoryLoading;
export const getSensorHistoryLoaded = (state: State) => state.sensorHistoryLoaded;
