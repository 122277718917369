import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionScope } from '@cargo-signal/shared';
import * as fromStore from '@app-root/store';
import { select, Store } from '@ngrx/store';
import { map, takeWhile } from 'rxjs/operators';

@Directive({ selector: '[appUserHasScope]' })
export class UserHasScopeDirective implements OnDestroy {
  private alive = true;
  private templateRendered = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store$: Store<fromStore.State>
  ) {}

  @Input() set appUserHasScope(desiredScopes: PermissionScope[]) {
    this.store$
      .pipe(
        select(fromStore.selectScopes),
        takeWhile(() => this.alive),
        map(scopes => {
          const currentScopes = scopes.map(scope => {
            const convertedValue = PermissionScope[scope];
            return convertedValue ? convertedValue : scope;
          });
          const userHasDesiredScopes = desiredScopes.every(scope => currentScopes.includes(scope));
          if (userHasDesiredScopes && !this.templateRendered) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.templateRendered = true;
          } else if (!userHasDesiredScopes) {
            this.viewContainer.clear();
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
