import { Action, createReducer, on } from '@ngrx/store';
import * as actions from '@app-root/store/actions/map-objects.actions';

export interface State {
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  loaded: false,
  loading: false
};

const mapObjectsReducer = createReducer(
  initialState,
  on(actions.loadMapLayers, (state, action) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(actions.loadMapLayersSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true
  })),
  on(actions.loadMapLayersFail, (state, action) => ({
    ...state,
    loading: false,
    loaded: false
  }))
);

export const getMapLoading = (state: State) => state.loading;
export const getMapLoaded = (state: State) => state.loaded;

export function reducer(state: State | undefined, action: Action) {
  return mapObjectsReducer(state, action);
}
