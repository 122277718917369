import { createAction, props } from '@ngrx/store';
import { Feature, Point } from 'geojson';
import { ShipmentDetails } from '@cargo-signal/shared';

export const loadShipmentsList = createAction('[ShipmentsList] Load Current Shipments List');

export const loadShipmentsListSuccess = createAction(
  '[ShipmentsList] Load Current Shipments List Success',
  props<{ shipments: Feature<Point, ShipmentDetails>[] }>()
);

export const loadShipmentsListFail = createAction(
  '[ShipmentsList] Load Current Shipments List Fail',
  props<{ error: any }>()
);

export const loadDraftShipmentsList = createAction('[ShipmentsList] Load Draft Shipments List');

export const loadDraftShipmentsListSuccess = createAction(
  '[ShipmentsList] Load Draft Shipments List Success',
  props<{ shipments: Feature<Point, ShipmentDetails>[] }>()
);

export const loadDraftShipmentsListFail = createAction(
  '[ShipmentsList] Load Draft Shipments List Fail',
  props<{ error: any }>()
);

export const loadCompleteShipmentsList = createAction('[ShipmentsList] Load Complete Shipments List');

export const loadCompleteShipmentsListSuccess = createAction(
  '[ShipmentsList] Load Complete Shipments List Success',
  props<{ shipments: Feature<Point, ShipmentDetails>[] }>()
);

export const loadCompleteShipmentsListFail = createAction(
  '[ShipmentsList] Load Complete Shipments List Fail',
  props<{ error: any }>()
);

export const setSelectedShipmentStatus = createAction(
  '[ShipmentsList] Set Selected Shipment Status',
  props<{ status: string }>()
);
