import { ShipmentStatus } from '@cargo-signal/shared';
import { State } from '@progress/kendo-data-query';

export const searchCriteriaCurrentShipments: State = {
  sort: [{ field: 'shipmentReference', dir: 'asc' }],
  filter: {
    logic: 'and',
    filters: [
      {
        filters: [
          {
            field: 'status',
            operator: 'eq',
            value: ShipmentStatus.CURRENT
          }
        ],
        logic: 'or'
      }
    ]
  }
};

export const searchCriteriaDraftShipments: State = {
  sort: [{ field: 'shipmentReference', dir: 'asc' }],
  filter: {
    logic: 'and',
    filters: [
      {
        filters: [
          {
            field: 'status',
            operator: 'eq',
            value: ShipmentStatus.DRAFT
          }
        ],
        logic: 'or'
      }
    ]
  }
};

export const searchCompleteCriteria: State = {
  sort: [{ field: 'shipmentReference', dir: 'asc' }],
  filter: {
    logic: 'and',
    filters: [
      {
        filters: [
          {
            field: 'status',
            operator: 'eq',
            value: ShipmentStatus.COMPLETE
          }
        ],
        logic: 'or'
      }
    ]
  }
};
