import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromStore from '@app-root/store';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { User } from '@cargo-signal/shared';
import { UserState } from '../../../store/reducers/user.reducer';

@Injectable({
  providedIn: 'root'
})
export class UserGuard {
  constructor(private store$: Store<fromStore.State>, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.checkStore();
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.canActivate(next, state);
  }

  checkStore(): Observable<boolean | UrlTree> {
    return this.store$.pipe(
      select(fromStore.getUserState),
      filter(userState => userState.loaded),
      map((state: UserState): true | UrlTree => {
        // regular users must accept the eula,
        // but guest flow users do not need to
        if (!state.guestView && !state.user.eulaAccepted) {
          return this.router.parseUrl('eula');
        }
        return true;
      })
    );
  }
}
