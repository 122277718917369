import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromRoot from '@app-root/store';
import { PermissionScope } from '@cargo-signal/shared';
import * as authActions from '@app-root/store/actions';

@Injectable({
  providedIn: 'root'
})
export class UserScopeGuard {
  constructor(private store$: Store<fromRoot.State>) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkStore(next.data.requiredScopes, state);
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkStore(next.data.requiredScopes, state);
  }

  /**
   * Checks the store to see if the user has the required scopes.
   */
  checkStore(requiredScopes: PermissionScope[], state: RouterStateSnapshot): Observable<boolean> {
    return this.store$.pipe(
      select(fromRoot.selectScopes),
      map(scopes => {
        if (!scopes || scopes.length === 0) {
          this.store$.dispatch(authActions.unauthorized({ route: state.url }));
          return false;
        }
        const currentScopes = scopes.map(scope => {
          const convertedValue = PermissionScope[scope];
          return convertedValue ? convertedValue : scope;
        });
        if (requiredScopes.every(scope => currentScopes.includes(scope))) {
          return true;
        } else {
          this.store$.dispatch(authActions.unauthorized({ route: state.url }));
          return false;
        }
      })
    );
  }
}
