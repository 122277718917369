// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-popup-card {
  margin: 0;
  color: #000000;
  border-radius: 13px;
  width: 343px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.detail-popup-card ion-card-content {
  padding-top: 0px;
}
.detail-popup-card ion-card-content h5 {
  font-size: 14px;
  border: none;
  padding-bottom: 4px;
  font-weight: 500;
}
.detail-popup-card ion-card-content p {
  font-size: 13px;
  color: #373a3d;
}`, "",{"version":3,"sources":["webpack://./src/app/shipment/components/presentation/shipment-details-summary/detail-map-popup/detail-map-popup.component.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,cAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,oBAAA;AACF;AACE;EACE,gBAAA;AACJ;AACI;EACE,eAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;AACN;AAEI;EACE,eAAA;EACA,cAAA;AAAN","sourcesContent":[".detail-popup-card {\n  margin: 0;\n  color: #000000;\n  border-radius: 13px;\n  width: 343px;\n  padding-top: 20px;\n  padding-bottom: 10px;\n\n  ion-card-content {\n    padding-top: 0px;\n\n    h5 {\n      font-size: 14px;\n      border: none;\n      padding-bottom: 4px;\n      font-weight: 500;\n    }\n\n    p {\n      font-size: 13px;\n      color: #373a3d;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
