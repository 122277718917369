import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { filter, map } from 'rxjs/operators';
import { ShipmentDetails } from '@cargo-signal/shared';

@Injectable({
  providedIn: 'root'
})
export class ShipmentDetailLoadedGuard {
  constructor(private store$: Store<fromRoot.State>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkStore(next.params.shipmentId);
  }

  checkStore(shipmentId: string): Observable<boolean> {
    return this.store$.pipe(
      select(fromRoot.shipmentDetails),
      map((shipmentDetails: ShipmentDetails) => {
        if (!shipmentDetails || shipmentDetails.shipmentId !== shipmentId) {
          this.store$.dispatch(fromRoot.loadShipmentDetails({ shipmentId, loadSensorHistory: true }));
          this.store$.dispatch(fromRoot.loadDeviceConfigurations({ shipmentId }));
          return false;
        } else {
          return true;
        }
      }),
      filter(loaded => loaded)
    );
  }
}
