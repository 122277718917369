import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  DeviceConfiguration,
  DeviceMetadata,
  DeviceService,
  ShipmentDetails,
  ShipmentService
} from '@cargo-signal/shared';
import * as actions from '../actions';
import { catchError, debounceTime, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromReducers from '../reducers';
import { deviceConfigurations, shipmentDetails } from '@app-root/store/selectors';
import { NotifierService } from '@core/services/notifier/notifier.service';

@Injectable()
export class ShipmentDevicesEffects {
  constructor(
    private store$: Store<fromReducers.State>,
    private actions$: Actions,
    private shipmentService: ShipmentService,
    private deviceService: DeviceService,
    private notifierService: NotifierService
  ) {}

  assignDevice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.assignDevice),
      map(action => action.device),
      switchMap(device => {
        return this.deviceService.fetchDeviceConfiguration(device).pipe(
          map(deviceConfig => deviceConfig),
          debounceTime(900),
          withLatestFrom(this.store$.pipe(select(deviceConfigurations)), this.store$.pipe(select(shipmentDetails))),
          switchMap(
            ([deviceConfig, devices, shipment]: [DeviceConfiguration, DeviceConfiguration[], ShipmentDetails]) => {
              return this.shipmentService
                .updateShipmentDeviceConfigurations(shipment?.shipmentId, [...devices, deviceConfig])
                .pipe(
                  tap(() => {
                    this.notifierService.showNotification('Device assigned successfully');
                  }),
                  switchMap(() => {
                    return [
                      actions.updateDeviceConfigurationSuccess({ devices: [...devices, deviceConfig] }),
                      actions.clearDeviceSearch(),
                      actions.loadShipmentDetails({ shipmentId: shipment.shipmentId }),
                      actions.go({ path: ['/shipments', 'details', shipment.shipmentId] })
                    ];
                  })
                );
            }
          ),
          catchError(error => of(actions.updateDeviceConfigurationFail({ error })))
        );
      })
    );
  });

  removeDeviceConfiguration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.removeDeviceConfiguration),
      map(action => action.deviceId),
      debounceTime(900),
      withLatestFrom(this.store$.pipe(select(deviceConfigurations)), this.store$.pipe(select(shipmentDetails))),
      switchMap(([deviceId, devices, shipment]: [string, DeviceConfiguration[], ShipmentDetails]) => {
        devices = JSON.parse(JSON.stringify(devices));
        const index = devices.findIndex(device => device?.deviceId === deviceId);
        devices.splice(index, 1);
        return this.shipmentService.updateShipmentDeviceConfigurations(shipment?.shipmentId, devices).pipe(
          tap(() => {
            this.notifierService.showNotification('Device removed');
          }),
          switchMap(() => [
            actions.updateDeviceConfigurationSuccess({ devices }),
            actions.loadShipmentDetails({ shipmentId: shipment.shipmentId })
          ]),
          catchError(error => of(actions.updateDeviceConfigurationFail({ error })))
        );
      })
    );
  });

  loadDeviceConfigurations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadDeviceConfigurations),
      map(action => action.shipmentId),
      switchMap(shipmentId => {
        return this.shipmentService.fetchShipmentDeviceConfigurations(shipmentId).pipe(
          map(deviceConfig => {
            return actions.loadDeviceConfigurationsSuccess({ deviceConfigurations: deviceConfig });
          }),
          catchError(error => of(actions.loadDeviceConfigurationsFail({ error })))
        );
      })
    );
  });

  searchForDevices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.searchForDevice),
      map(action => action.searchStr),
      switchMap(searchStr => {
        return this.deviceService.searchForDevicesByDeviceNumber(searchStr, true).pipe(
          map(searchResults => actions.searchForDeviceSuccess({ searchResults })),
          catchError(error => of(actions.searchForDeviceFail({ error })))
        );
      })
    );
  });

  scanDeviceNumber$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.scanDeviceNumber),
      map(action => action.deviceNumber),
      switchMap(deviceNumber => {
        return this.deviceService.searchForDevicesByDeviceNumber(deviceNumber, true).pipe(
          withLatestFrom(this.store$.pipe(select(shipmentDetails))),
          switchMap(([searchResults, shipment]: [DeviceMetadata[], ShipmentDetails]) => {
            if (searchResults && searchResults.length) {
              return [
                actions.searchForDeviceSuccess({ searchResults }),
                actions.scanDeviceNumberSuccess(),
                actions.go({ path: ['/shipments', 'details', shipment.shipmentId, 'devices'] })
              ];
            } else {
              return [actions.scanDeviceNumberFail({ error: 'Device ID not detected' })];
            }
          }),
          catchError(error => of(actions.searchForDeviceFail({ error })))
        );
      })
    );
  });

  scanDeviceNumberFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.scanDeviceNumberFail),
        map(action => action.error),
        tap(() => this.notifierService.showNotification('Device ID not detected'))
      );
    },
    { dispatch: false }
  );

  updateDeviceConfigurationFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.updateDeviceConfigurationFail),
        map(action => action.error),
        tap(() => this.notifierService.showNotification('This action could not be completed. Please try again later.'))
      );
    },
    { dispatch: false }
  );
}
