import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class PollingManagerService {
  /**
   * A map of actions that should be dispatched each time polling occurs.
   */
  private actionMap = new Map<string, Action>();
  constructor() {}

  /**
   * Adds an action for the specified key to the map
   */
  public registerActionForPolling(key: string, action: Action): void {
    this.actionMap.set(key, action);
  }

  /**
   * Removes the action for the specified key to the map
   */
  public delistActionForPolling(key: string): void {
    this.actionMap.delete(key);
  }

  /**
   * Returns all the values of the action map as an array of actions.
   */
  public getActionsForPolling(): Action[] {
    return Array.from(this.actionMap.values());
  }
}
