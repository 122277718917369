import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromRoutes from '../reducers/shipment-routes.reducer';
import { Route, RouteWaypoint } from '@cargo-signal/shared';

export const plannedRoute = createSelector(fromFeature.getShipmentRoutesState, fromRoutes.getPlannedRoute);

export const plannedRouteLoading = createSelector(
  fromFeature.getShipmentRoutesState,
  fromRoutes.getPlannedRouteLoading
);

export const plannedRouteLoaded = createSelector(fromFeature.getShipmentRoutesState, fromRoutes.getPlannedRouteLoaded);

export const actualRoute = createSelector(fromFeature.getShipmentRoutesState, fromRoutes.getActualRoute);

export const actualRouteLoading = createSelector(fromFeature.getShipmentRoutesState, fromRoutes.getActualRouteLoading);

export const actualRouteLoaded = createSelector(fromFeature.getShipmentRoutesState, fromRoutes.getActualRouteLoaded);

export const routeWaypoints = createSelector(plannedRoute, (route: Route) => {
  const waypoints: RouteWaypoint[] = [];
  const filteredFeatures = route.features.filter(feature => feature.routeWaypoint);
  filteredFeatures.forEach(feature => waypoints.push(feature.routeWaypoint));
  return waypoints.sort((a, b) => a.routeWaypointOrder - b.routeWaypointOrder);
});

export const currentLocation = createSelector(fromFeature.getShipmentRoutesState, fromRoutes.getCurrentLocation);
