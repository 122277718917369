import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { DynamicComponentService, MapService } from '@cargo-signal/atlas-mapbox';
import * as actions from '../actions/shipment-routes.actions';
import { select, Store } from '@ngrx/store';
import * as fromSelectors from '../selectors';
import { Feature, Geometry } from 'geojson';
import * as fromReducers from '@app-root/store/reducers';
import { GeospatialUtilsService, SensorHistoryEntry, ShipmentService } from '@cargo-signal/shared';
import { Injectable } from '@angular/core';
import { actualRouteLayerConfig, actualRouteLocationsLayerConfig } from '../../../assets/shipment-details-map.config';
import { compareDesc } from 'date-fns';
import { DetailMapPopupComponent } from '@app-root/shipment/components/presentation/shipment-details-summary/detail-map-popup/detail-map-popup.component';
import * as mapboxGl from 'mapbox-gl';

@Injectable()
export class ShipmentRoutesEffects {
  constructor(
    private actions$: Actions,
    private shipmentService: ShipmentService,
    private store$: Store<fromReducers.State>,
    private gsUtils: GeospatialUtilsService,
    private mapService: MapService,
    private dynamicComponentService: DynamicComponentService
  ) {}

  loadPlannedRoute$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadPlannedRoute),
      map(action => action.shipmentId),
      switchMap(shipmentId => {
        return this.shipmentService.fetchShipmentPlannedRoute(shipmentId).pipe(
          map(plannedRoute => {
            return actions.loadPlannedRouteSuccess({ plannedRoute });
          }),
          catchError(error => {
            return of(actions.loadPlannedRouteFail({ error }));
          })
        );
      })
    );
  });

  loadActualRoute$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadActualRoute),
      map(action => action.shipmentId),
      switchMap(shipmentId => {
        return this.shipmentService.fetchShipmentActualRoute(shipmentId).pipe(
          map(actualRoute => {
            return actions.loadActualRouteSuccess({ actualRoute });
          }),
          catchError(err => of(actions.loadActualRouteFail({ err })))
        );
      })
    );
  });

  /**
   * An effect that listens for PutActualRouteOnMap action and updates the actual route data layer.
   */
  refreshActualRouteDataLayer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.drawActualRoute),
        map(action => action.actualRoute),
        withLatestFrom(this.store$.pipe(select(fromSelectors.selectSensorHistory))),
        map(([actualRoute, shipmentSensorHistory]) => {
          this.drawRoute(actualRoute, shipmentSensorHistory);
        })
      ),
    { dispatch: false }
  );

  private drawRoute(actualRoute, shipmentSensorHistory) {
    // add the route to the map
    const route = {
      ...actualRoute,
      features: actualRoute.features.filter(feat => feat.geometry.type === 'LineString')
    };

    if (route.features.length > 0) {
      const sensorHistory: SensorHistoryEntry[] = [
        ...shipmentSensorHistory.filter(entry => entry.location)
      ].sort((m1, m2) => compareDesc(m1.sensorTimestamp, m2.sensorTimestamp));

      this.mapService.upsertDataLayer(route, [actualRouteLayerConfig], 'shipment-actual-route');

      // create device update locations array from sensor history
      const routePoints: Feature<Geometry>[] = sensorHistory.map(entry => {
        return {
          type: 'Feature',
          properties: {
            address: entry.address,
            timestamp: entry.sensorTimestamp
          },
          geometry: entry.location
        };
      });

      // add the locations on top of the route
      this.mapService.addGeoJsonDataLayer(
        {
          ...actualRoute,
          features: routePoints
        },
        [actualRouteLocationsLayerConfig],
        'shipment-actual-route-points',
        null,
        false,
        this.openPopup
      );
    }
  }

  openPopup = (e: mapboxGl.MapMouseEvent) => {
    return this.dynamicComponentService.injectComponent(DetailMapPopupComponent, x => {
      x.entry = e.features[0].properties;
    });
  }
}
