import { Pipe, PipeTransform } from '@angular/core';
import { getDateTime, getDateTimeFormatString } from '@cargo-signal/shared';
import { formatDate, parseDate } from '@progress/kendo-angular-intl';

@Pipe({
  name: 'formattedDate'
})
export class FormattedDatePipe implements PipeTransform {
  /**
   * Calls date util to format the input date and convert based on user's preferred timezone
   * @param date the date object or a complete ISO8601 date string to format
   */
  transform(date: Date | string): any {
    if (date) {
      return formatDate(getDateTime(typeof date === 'string' ? parseDate(date) : date), getDateTimeFormatString());
    }
    return '';
  }
}
