import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { PermissionScope } from '@cargo-signal/shared';

@Injectable({
  providedIn: 'root'
})
export class ShipmentAlertsLoadedGuard {
  constructor(private store$: Store<fromRoot.State>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkStore(next.params.shipmentId).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(shipmentId: string): Observable<boolean> {
    return this.store$.pipe(
      select(fromRoot.shipmentAlertsLoaded),
      withLatestFrom(this.store$.pipe(select(fromRoot.selectScopes))),
      map(([loaded, scopes]) => {
        const currentScopes = scopes.map(scope => {
          const convertedValue = PermissionScope[scope];
          return convertedValue ? convertedValue : scope;
        });
        if (!loaded && currentScopes.includes(PermissionScope.READ_ALERT_ITEMS)) {
          this.store$.dispatch(fromRoot.loadShipmentAlerts({ shipmentId }));
          return false;
        } else {
          return true;
        }
      }),
      filter(loaded => loaded)
    );
  }
}
