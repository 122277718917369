import * as fromShipments from '../actions/shipments-list.actions';
import * as fromUsers from '../actions/user.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { ShipmentDetails, ShipmentStatus } from '@cargo-signal/shared';
import { Feature, Point } from 'geojson';

export interface State {
  shipments: Feature<Point, ShipmentDetails>[];
  draftShipmentsLoading: boolean;
  draftShipmentsLoaded: boolean;
  completeShipmentsLoading: boolean;
  completeShipmentsLoaded: boolean;
  loading: boolean;
  loaded: boolean;
  selectedStatus: string;
}

export const initialState: State = {
  shipments: [],
  draftShipmentsLoading: false,
  draftShipmentsLoaded: false,
  completeShipmentsLoading: false,
  completeShipmentsLoaded: false,
  loading: false,
  loaded: false,
  selectedStatus: ShipmentStatus.CURRENT
};

const shipmentsReducer = createReducer(
  initialState,
  on(fromUsers.setActiveCompanySuccess, (state, action) => ({
    ...state,
    shipments: [],
    loading: true,
    loaded: false,
    draftShipmentsLoading: true,
    draftShipmentsLoaded: false,
    completeShipmentsLoading: true,
    completeShipmentsLoaded: false,
    selectedStatus: ShipmentStatus.CURRENT  //re-init status to current like initialState
  })),
  on(fromShipments.setSelectedShipmentStatus, (state, action) => ({
    ...state,
    selectedStatus: action.status
  })),
  on(fromShipments.loadShipmentsList, (state, action) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(fromShipments.loadShipmentsListSuccess, (state, action) => ({
    ...state,
    shipments: [...state.shipments, ...action.shipments],
    loading: false,
    loaded: true
  })),
  on(fromShipments.loadShipmentsListFail, (state, action) => ({
    ...state,
    loading: false,
    loaded: false
  })),
  on(fromShipments.loadDraftShipmentsList, (state, action) => ({
    ...state,
    draftShipmentsLoading: true,
    draftShipmentsLoaded: false
  })),
  on(fromShipments.loadDraftShipmentsListSuccess, (state, action) => ({
    ...state,
    shipments: [...state.shipments, ...action.shipments],
    draftShipmentsLoading: false,
    draftShipmentsLoaded: true
  })),

  on(fromShipments.loadDraftShipmentsListFail, (state, action) => ({
    ...state,
    draftShipmentsLoading: false,
    draftShipmentsLoaded: false
  })),
  on(fromShipments.loadCompleteShipmentsList, (state, action) => ({
    ...state,
    completeShipmentsLoading: true,
    completeShipmentsLoaded: false
  })),
  on(fromShipments.loadCompleteShipmentsListSuccess, (state, action) => ({
    ...state,
    shipments: [...state.shipments, ...action.shipments],
    completeShipmentsLoading: false,
    completeShipmentsLoaded: true
  })),
  on(fromShipments.loadCompleteShipmentsListFail, (state, action) => ({
    ...state,
    completeShipmentsLoading: false,
    completeShipmentsLoaded: false
  }))
);

export const getShipmentsLoading = (state: State) => state.loading;
export const getShipmentsLoaded = (state: State) => state.loaded;
export const getDraftShipmentsLoaded = (state: State) => state.draftShipmentsLoaded;
export const getDraftShipmentsLoading = (state: State) => state.draftShipmentsLoading;
export const getCompleteShipmentsLoaded = (state: State) => state.completeShipmentsLoaded;
export const getCompleteShipmentsLoading = (state: State) => state.completeShipmentsLoading;
export const getSelectedStatus = (state: State) => state.selectedStatus;
export const getShipments = (state: State) => state.shipments;

export function reducer(state: State | undefined, action: Action) {
  return shipmentsReducer(state, action);
}
