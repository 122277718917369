import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { UnauthorizedPageComponent } from '@core/auth/components/unauthorized/unauthorized.page';
import { LoginPageComponent } from '@core/auth/components/login/login.page';
import { EulaPageComponent } from './auth/components/eula/eula.page';
import { SharedModule } from '@app-root/shared/shared.module';

@NgModule({
  imports: [CommonModule, HttpClientModule, RouterModule, FormsModule, IonicModule, SharedModule],
  declarations: [LoginPageComponent, UnauthorizedPageComponent, EulaPageComponent],
  exports: [LoginPageComponent, UnauthorizedPageComponent, EulaPageComponent]
})
export class CoreModule {}
