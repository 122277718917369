import { createAction, props } from '@ngrx/store';
import { DeviceMetadata, DeviceConfiguration } from '@cargo-signal/shared';

export const loadDeviceConfigurations = createAction(
  '[ShipmentDevices] Load Device Configurations',
  props<{ shipmentId: string }>()
);

export const loadDeviceConfigurationsSuccess = createAction(
  '[ShipmentDevices] Load Device Configurations Success',
  props<{ deviceConfigurations: DeviceConfiguration[] }>()
);

export const loadDeviceConfigurationsFail = createAction(
  '[ShipmentDevices] Load Device Configurations Fail',
  props<{ error: any }>()
);

export const setShipmentDevices = createAction(
  '[ShipmentDevices] Set Shipment Devices',
  props<{ devices: DeviceMetadata[] }>()
);

export const searchForDevice = createAction(
  '[ShipmentDevices] Search for Shipment Device',
  props<{ searchStr: string }>()
);

export const scanDeviceNumber = createAction('[ShipmentDevices] Scan Device Number', props<{ deviceNumber: string }>());

export const scanDeviceNumberSuccess = createAction('[ShipmentDevices] Scan Device Number Success');

export const scanDeviceNumberFail = createAction('[ShipmentDevices] Scan Device Number Fail', props<{ error: any }>());

export const assignDevice = createAction('[ShipmentDevices] Assign Device', props<{ device: DeviceMetadata }>());

export const updateDeviceConfigurationSuccess = createAction(
  '[ShipmentDevices] Update Device Configuration Success',
  props<{ devices: DeviceConfiguration[] }>()
);

export const updateDeviceConfigurationFail = createAction(
  '[ShipmentDevices] Update Device Configuration Fail',
  props<{ error: any }>()
);

export const removeDeviceConfiguration = createAction(
  '[ShipmentDevices] Remove DeviceConfiguration',
  props<{ deviceId: string }>()
);

export const searchForDeviceSuccess = createAction(
  '[ShipmentDevices] Search for Shipment Success',
  props<{ searchResults: DeviceMetadata[] }>()
);

export const clearDeviceSearch = createAction('[ShipmentDevices] Clear Device Search');

export const searchForDeviceFail = createAction('[ShipmentDevices] Search for Device Fail', props<{ error: any }>());
