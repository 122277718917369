import { createSelector } from '@ngrx/store';
import * as fromRoot from '@app-root/store/reducers';
import * as fromShipmentDetails from '@app-root/store/reducers/shipment-details.reducer';

export function getShipmentDetailsState(state: fromRoot.State) {
  return state.shipmentDetails;
}

export const shipmentDetails = createSelector(getShipmentDetailsState, fromShipmentDetails.getShipment);
export const shipmentDetailsLoading = createSelector(getShipmentDetailsState, fromShipmentDetails.getShipmentLoading);
export const shipmentDetailsLoaded = createSelector(getShipmentDetailsState, fromShipmentDetails.getShipmentLoaded);
