import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as sensorDataActions from '@app-root/store/actions/sensor-data.actions';
import * as shipmentRoutesActions from '@app-root/store/actions/shipment-routes.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  SensorHistoryEntry,
  SensorHistoryService,
  ShipmentCurrentSensorData,
  ShipmentService
} from '@cargo-signal/shared';
import { of } from 'rxjs';
import { Feature, Point } from 'geojson';
import { compareAsc } from 'date-fns';

@Injectable()
export class SensorDataEffects {
  constructor(
    private actions$: Actions,
    private shipmentService: ShipmentService,
    private sensorHistoryService: SensorHistoryService
  ) {}

  loadCurrentSensorData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sensorDataActions.loadCurrentSensorData),
      map(action => action.shipmentId),
      switchMap(shipmentId => {
        return this.shipmentService.fetchCurrentSensorDataForShipment(shipmentId).pipe(
          switchMap((sensorData: ShipmentCurrentSensorData) => {
            const results = [];
            // Filter out data types that shouldn't be displayed
            sensorData.currentData = sensorData.currentData.filter(
              data => !['FIX_TYPE', 'VOLTAGE'].includes(data.type.toUpperCase())
            );
            // Format data types to match SensorHistoryEntry values
            sensorData.currentData.map(data => {
              data.type = data.type === 'BATTERY_PERCENTAGE' ? 'batteryPercentage' : data.type.toLowerCase();
            });
            results.push(sensorDataActions.loadCurrentSensorDataSuccess({ sensorData }));
            if (sensorData.currentLocation) {
              const currentLocation: Feature<Point> = {
                type: 'Feature',
                properties: {
                  categoryType: 'CURRENT_LOCATION'
                },
                geometry: sensorData.currentLocation
              };
              results.push(shipmentRoutesActions.setCurrentLocation({ currentLocation }));
            }
            return results;
          }),
          catchError(error => {
            return of(sensorDataActions.loadCurrentSensorDataFail({ error }));
          })
        );
      })
    );
  });

  loadSensorHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sensorDataActions.loadSensorHistory),
      switchMap(action => {
        const timeRange = action.endDate ? { start: action.startDate, end: action.endDate } : null;
        return this.sensorHistoryService.fetchShipmentSensorHistory(action.shipmentId, timeRange).pipe(
          map((sensorHistory: SensorHistoryEntry[]) => {
            // Sort by sensorTimestamp
            sensorHistory.sort((a, b) => compareAsc(a.sensorTimestamp, b.sensorTimestamp));
            return sensorDataActions.loadSensorHistorySuccess({ sensorHistory });
          }),
          catchError(error => {
            return of(sensorDataActions.loadSensorHistoryFail({ error }));
          })
        );
      })
    );
  });
}
