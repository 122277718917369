import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromShipmentsList from '../reducers/shipments-list.reducer';
import { Feature } from 'geojson';

export const allShipments = createSelector(fromFeature.getShipmentsListState, fromShipmentsList.getShipments);
export const getSelectedStatus = createSelector(fromFeature.getShipmentsListState, fromShipmentsList.getSelectedStatus);

export const getShipmentsByStatus = createSelector(
  allShipments,
  getSelectedStatus,
  (shipments: Feature[], status: string) => {
    return shipments.filter((s: Feature) => s.properties.status === status);
  }
);

export const getShipmentsBySearch = createSelector(getShipmentsByStatus, (shipments: Feature[], props) => {
  if (!props.searchStr) {
    return shipments;
  } else {
    return shipments.filter(s => s.properties.shipmentReference.toLowerCase().includes(props.searchStr.toLowerCase()));
  }
});

export const shipmentsLoading = createSelector(
  fromFeature.getShipmentsListState,
  fromShipmentsList.getShipmentsLoading
);

export const shipmentsLoaded = createSelector(fromFeature.getShipmentsListState, fromShipmentsList.getShipmentsLoaded);

export const draftShipmentsLoaded = createSelector(
  fromFeature.getShipmentsListState,
  fromShipmentsList.getDraftShipmentsLoaded
);

export const draftShipmentsLoading = createSelector(
  fromFeature.getShipmentsListState,
  fromShipmentsList.getDraftShipmentsLoading
);

export const completeShipmentsLoaded = createSelector(
  fromFeature.getShipmentsListState,
  fromShipmentsList.getCompleteShipmentsLoaded
);

export const completeShipmentsLoading = createSelector(
  fromFeature.getShipmentsListState,
  fromShipmentsList.getCompleteShipmentsLoading
);
