import { Component, Input } from '@angular/core';
import { ShipmentDetails } from '@cargo-signal/shared';

@Component({
  selector: 'app-map',
  templateUrl: './map-popup.component.html',
  styleUrls: ['./map-popup.component.scss']
})
export class MapPopupComponent {
  @Input() shipment: ShipmentDetails;

  constructor() {}
}
