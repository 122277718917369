import { createAction, props } from '@ngrx/store';
import { AlertNotification } from '@cargo-signal/shared';

export const loadShipmentAlerts = createAction('[ShipmentAlert] Load Shipment Alerts', props<{ shipmentId: string }>());

export const loadShipmentAlertsSuccess = createAction(
  '[ShipmentAlert] Load Shipment Alerts Success',
  props<{ shipmentAlerts: AlertNotification[] }>()
);

export const loadShipmentAlertsFail = createAction(
  '[ShipmentAlert] Load Shipment Alerts Fail',
  props<{ error: any }>()
);

export const resetShipmentAlerts = createAction('[ShipmentAlert] Reset Shipment Alerts');
